// src/pages/DashboardPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './DashboardPage.css';

const DashboardPage = ({ setPageName }) => {
  useEffect(() => {
    setPageName("Dashboard Page"); // Set the page name when the component is mounted
  }, [setPageName]);


  const [units, setUnits] = useState([]);
  const [expandedUnits, setExpandedUnits] = useState({});
  const [quotes, setQuotes] = useState([]);
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUnits = async () => {
      const user = auth.currentUser;
      if (user) {
        const userUnitsRef = collection(db, 'users', user.uid, 'units');
        const unitsSnapshot = await getDocs(userUnitsRef);
        const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUnits(unitsList);
      }
    };

    const fetchQuotes = async () => {
      const quotesArray = [
        "The best way to predict your future is to create it. - Peter Drucker",
        "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
        "Keep your face always toward the sunshine—and shadows will fall behind you. - Walt Whitman",
      ];
      setQuotes(quotesArray);
    };

    fetchUnits();
    fetchQuotes();
  }, [auth, db]);

  const toggleUnitExpansion = async (unitCode) => {
    setExpandedUnits((prevExpandedUnits) => ({
      ...prevExpandedUnits,
      [unitCode]: !prevExpandedUnits[unitCode],
    }));

    if (!expandedUnits[unitCode]) {
      const decksCollection = collection(db, 'units', unitCode, 'decks');
      const decksSnapshot = await getDocs(decksCollection);
      const decksList = decksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setUnits((prevUnits) =>
        prevUnits.map((unit) =>
          unit.unit_code === unitCode ? { ...unit, decks: decksList } : unit
        )
      );
    }
  };

  const handleStartReview = (deckId) => {
    navigate(`/review/${deckId}`);
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome to Your Dashboard</h1>
      <div className="widgets">
        <div className="widget">
          <h2>Your Units</h2>
          <ul>
            {units.map(unit => (
              <li key={unit.unit_code} className="clickable-unit">
                {unit.unit_full}
              </li>
            ))}
          </ul>
        </div>
        <div className="widget">
          <h2>Motivational Quotes</h2>
          <ul>
            {quotes.map((quote, index) => (
              <li key={index}>{quote}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="expandable-units">
        <h2>Study Units</h2>
        <ul>
          {units.map(unit => (
            <li key={unit.unit_code}>
              <div className="unit-header" onClick={() => toggleUnitExpansion(unit.id)}>
                {unit.unit_full} {expandedUnits[unit.unit_code] ? '-' : '+'}
              </div>
              {expandedUnits[unit.unit_code] && (
                <ul className="decks-list">
                  {unit.decks && unit.decks.map(deck => (
                    <li key={deck.unit_ID} className="deck-item">
                      <div className="deck-name">{deck.deck_name}</div>
                      <button onClick={() => handleStartReview(deck.id)}>Start Review</button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardPage;
