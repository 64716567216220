// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import PageAnnotationHeader from '../../components/PageAnnotationHeader';
import './HomePage.css';
import BouncingBalls from '../../BouncingBalls';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import BuyMeACoffeeButton from '../../components/MonetizationComponents/BuyMeACoffeeButton';
import BuyMeACoffeeWidget from '../../components/MonetizationComponents/BuyMeACoffeeWidget';




const HomePage = ({ setPageName }) => {
  useEffect(() => {
    setPageName("Home Page"); // Set the page name when the component is mounted
  }, [setPageName]);

  const navigate = useNavigate();
  const [showAllNotes, setShowAllNotes] = useState(false);

  const handleStart = () => {
    navigate('/user-info');
  };


  const releaseNotes = [
    "Initial release with core features.",
    "Added user authentication.",
    "Improved performance and bug fixes.",
    "Added new study modes.",
    "UI/UX enhancements.",
    "More release notes...",
  ];

  return (
    <div className="home-container">
          {/* <PageAnnotationHeader pageName = 'Home Page' /> */}

      {/* <BouncingBalls /> */}
      {/* <Navbar /> */}
      {/* <Header /> */}
      <div className="content">
        <section className="welcome-section">
          <h1>Welcome to Quizzo</h1>
          <p>Quizzo is your ultimate study companion. Our platform helps you organize your study materials, track your progress, and optimize your learning with advanced algorithms.</p>
          {/* <button onClick={handleStart}>Start</button> */}
            {/* <BuyMeACoffeeButton/> */}
          {/* <BuyMeACoffeeWidget/> */}
        </section>
        
        <section className="release-notes-section">
          <h2>Release Notes</h2>
          <ul>
            {releaseNotes.slice(0, showAllNotes ? releaseNotes.length : 5).map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
          <button onClick={() => setShowAllNotes(!showAllNotes)}>
            {showAllNotes ? "Show Less" : "Show More"}
          </button>
        </section>

        {/* <section className="video-section">
          <h2>How to Get Started</h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/YOUR_VIDEO_ID?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </section> */}

        <section className="about-section">
          <h2>About Quizzo</h2>
          {/* <img src="/path/to/your/image.jpg" alt="About Quizzo" /> */}
          <p>Quizzo was created to help students maximize their learning potential. We believe that with the right tools, anyone can achieve academic success. Our goal is to make studying more efficient, organized, and enjoyable.</p>
        </section>

        <footer className="footer-section">
          <div className="footer-content">
            <p>Contact: <a href="mailto:support@bonsai.com">support@Quizzo.io</a></p>
            {/* <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a></p> */}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;
