import React from "react";

const PageAnnotationHeader = ({ pageName }) => {
  if (process.env.NODE_ENV !== "development") return null; // Only render in development mode

  return (
    <header style={styles.header}>
      <h1 style={styles.title}>{pageName || "No Page Name Set"}</h1>
    </header>
  );
};

const styles = {
    header: {
      position: "fixed",
      bottom: 0, // Move it to the bottom
      width: "100%",
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      textAlign: "center",
      padding: "10px 0",
      zIndex: 1000,
    },
    title: {
      margin: 0,
      fontSize: "18px",
    },
    content: {
      paddingBottom: "60px", // Ensure space for the fixed header at the bottom
    },
  };

  

export default PageAnnotationHeader;
