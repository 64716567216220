import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, addDoc } from 'firebase/firestore';
import { useSwipeable } from 'react-swipeable'; // Import swipe functionality

import BottomFunctionBar from '../../components/StudyManageContentComponents/BottomFunctionBar';
import CreateDeckModal from '../../components/StudyManageContentComponents/CreateDeckModal'; 
import DeckList from '../../components/StudyManageContentComponents/DeckList'; // Import the new DeckList component

import { useNavigate } from 'react-router-dom'; // Import useNavigate


const StudyPage = ({ setPageName }) => {
  useEffect(() => {
    setPageName("Study Page"); // Set the page name when the component is mounted
  }, [setPageName]);

  const navigate = useNavigate(); // Initialize navigate


  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedUnit, setExpandedUnit] = useState(null);
  const [expandedDeck, setExpandedDeck] = useState(null);
  const [showModal, setShowModal] = useState(false); // Manage modal state here
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null); // Track unit to delete

  // Function to fetch units from Firestore
  const fetchUnitsAndDecks = async () => {
    try {
      const unitsCollection = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollection);
      const unitsList = [];

      for (const unitDoc of unitsSnapshot.docs) {
        const unitData = unitDoc.data();
        const unitId = unitDoc.id;

        // Fetch decks subcollection for each unit
        const decksCollection = collection(db, `units/${unitId}/decks`);
        const decksSnapshot = await getDocs(decksCollection);
        const decksList = decksSnapshot.docs.map(deckDoc => ({
          id: deckDoc.id,
          ...deckDoc.data(),
        }));

        unitsList.push({
          ...unitData,
          unitId, // Use the Firestore document ID as the unitId
          unit_decks: decksList,
        });
      }

      setUnits(unitsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching units and decks:', error);
    }
  };

  useEffect(() => {
    fetchUnitsAndDecks();
  }, []);

  const toggleUnit = (unitIndex) => {
    setExpandedUnit(expandedUnit === unitIndex ? null : unitIndex);
  };

  const toggleDeck = (deckIndex) => {
    setExpandedDeck(expandedDeck === deckIndex ? null : deckIndex);
  };

    // Function to handle edit button click
    const handleEditClick = (unitId) => {
      navigate(`/study/edit-unit/${unitId}`);
    };

  const handleCreateDeck = async (newUnit) => {
    try {
      // Add the new unit to Firestore
      const docRef = await addDoc(collection(db, 'units'), {
        unit_name: newUnit.unit_name,
        unit_full: newUnit.unit_full,
      });

      // Add the new unit to the state
      setUnits((prevUnits) => [
        ...prevUnits,
        {
          unitId: docRef.id, // Firestore-generated ID
          ...newUnit, // New unit data
          unit_decks: [], // Initialize with an empty decks array
        },
      ]);
    } catch (error) {
      console.error('Error adding new unit:', error);
    }
  };

  // Swipe-to-delete functionality
  const handleDeleteSwipe = (unit) => {
    setUnitToDelete(unit);
    setShowConfirmation(true); // Show confirmation modal
  };


  const handleDeleteClick = (unit) => {
    console.log('Deleting unit:', unit); // Debug log to check the unit being set
    if (unit && unit.unitId) {
      setUnitToDelete(unit);
      setShowConfirmation(true); // Show confirmation modal when "X" is clicked
    } else {
      console.error('Error: The unit does not have a valid unitId', unit);
    }
  };

  const confirmDelete = async () => {
    if (unitToDelete && unitToDelete.unitId) {
      try {
   
        await deleteDoc(doc(db, 'units', unitToDelete.unitId)); // Delete from Firestore
        setUnits(units.filter((u) => u.unitId !== unitToDelete.unitId)); // Remove from state
        setShowConfirmation(false);
        setUnitToDelete(null); // Reset the unitToDelete
      } catch (error) {
        console.error('Error deleting document: ', error);
      }
    } else {
      console.error('Error: unitToDelete is undefined or missing unitId');
    }
  };

  // Custom swipeable hook handler (moved outside map)
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleDeleteSwipe(unitToDelete),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="max-w-5xl border-t-0 mx-auto rounded-lg shadow-md overflow-hidden md:max-w-7xl mt-8 dark:via-neutral-400">
        <div style={{ padding: '20px' }}>
          <h2>Flashcard Units</h2>
          {units.map((unit, unitIndex) => (
            <div
              key={unit.unit_code}
              style={{
    
                marginBottom: '20px',
                border: '1px solid #ccc',
                padding: '10px',
                position: 'relative',  // Add position relative here to ensure proper positioning of the "X" button
              }}
            >

                                 {/* Edit button for editing unit */}
          <button
            onClick={() => handleEditClick(unit.unitId)}
            style={{
              position: 'absolute',
              right: '60px', // Adjust to position next to the delete button
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              fontSize: '60px',
              color: 'blue',
              cursor: 'pointer',
              
            }}
          >
            ✎
          </button>
              {/* X button for deletion */}
              <button
                onClick={() => handleDeleteClick(unit)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',                  // Center vertically
                  transform: 'translateY(-50%)', // Offset to perfectly center the button
                  background: 'transparent',
                  border: 'none',
                  fontSize: '60px',             // Increase the font size for a larger "X"
                  color: 'red',
                  cursor: 'pointer',
                }}
              >
                ×
              </button>
              <div 
                onClick={() => toggleUnit(unitIndex)} 
                style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '18px' }}
              >
                {unit.unit_full}
              </div>

              

              {expandedUnit === unitIndex && (
                <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                  {unit.unit_decks.map((deck, deckIndex) => (
                    <div key={deck.deck_name} style={{ marginBottom: '10px' }}>
                      <div
                        onClick={() => toggleDeck(deckIndex)}
                        style={{ cursor: 'pointer', fontWeight: 'bold', color: '#007BFF' }}
                      >
                      <div
                        onClick={() => navigate(`/study/units/${unit.unitId}/decks/${deck.id}`)}
                        style={{ cursor: 'pointer', fontWeight: 'bold', color: '#007BFF' }}
                      >
                        {deck.deck_name}
                      </div>                     
                       </div>

                      {/* {expandedDeck === deckIndex && (
                        <div style={{ paddingLeft: '20px' }}>
                          {deck.flashcards && deck.flashcards.length > 0 ? (
                            deck.flashcards.map((flashcard, flashcardIndex) => (
                              <div key={flashcardIndex} style={{ marginBottom: '5px' }}>
                                <strong>Q:</strong> {flashcard.question}<br />
                                <strong>A:</strong> {flashcard.answer}
                              </div>
                            ))
                          ) : (
                            <p>No flashcards available</p>
                          )}
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Pass setShowModal to BottomFunctionBar */}
      <BottomFunctionBar setShowModal={setShowModal} />

      {/* Create Deck Modal */}
      {showModal && (
        <CreateDeckModal 
          showModal={showModal} 
          setShowModal={setShowModal}
          onCreateDeck={handleCreateDeck} 
        />
      )}
      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Are you sure you want to delete this unit?</h2>
            <button onClick={() => setShowConfirmation(false)} className="cancel-button">
              Cancel
            </button>
            <button onClick={confirmDelete} className="ok-button">
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


export default StudyPage;
