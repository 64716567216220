import React from 'react';
import './index.css';

import { createRoot } from 'react-dom/client';  // Correct import from 'react-dom/client'
import App from './App';

import ReactGA from 'react-ga';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
serviceWorkerRegistration.register();

const TRACKING_ID = "G-LPHB5JZ1X9"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById('root');
const root = createRoot(container);  // Create the root
root.render(<App />);  // Render the App
